import axios from "axios";
import { getToken, removeStorage, removeToken } from "./localStorageUtils";
import store from "./../Store/indexStore";
import { logoutAction } from "./../Store/Actions/authAction";
import { setUserProfileToNullAction } from "./../Store/Actions/userAction";
import { AlertError } from "./../Utils/alertUtils";
import i18n from "../i18n";

const API_KEY_FROM_E_FUTURE = process.env.REACT_APP_API_KEY_FROM_E_FUTURE;

class AxiosService {
  constructor() {
    const instance = axios.create();
    this.instance = instance;
  }

  get(url, data) {
    return this.instance.get(url, data);
  }

  post(url, data) {
    return this.instance.post(url, data);
  }

  //
  getWithTokenOnHeaders(url) {
    const config = {
      headers: { "x-access-token": `${getToken()}` },
    };
    return this.instance.get(url, config);
  }

  getWithToken(url) {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    };
    return this.instance.get(url, config);
  }

  postLogin(url, data) {
    const username = data.email;
    const password = data.password;
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    const config = {
      headers: { Authorization: `Basic ${token}` },
    };
    return this.instance.post(url, data, config);
  }

  postWithTokenOnHeaders(url, data) {
    const { access_token } = data;
    const config = {
      headers: { "x-access-token": access_token },
    };
    return this.instance.post(url, data, config);
  }

  postWithToken(url, data) {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    };
    return this.instance.post(url, data, config);
  }

  patchWithToken(url, data) {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
    };
    return this.instance.patch(url, data, config);
  }

  patchUploadImageWithToken(url, data) {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    return this.instance.patch(url, data, config);
  }

  postUploadImageWithToken(url, data) {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    return this.instance.post(url, data, config);
  }

  postUploadDocumentWithToken(url, data) {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    return this.instance.post(url, data, config);
  }

  deleteWithToken(url, data) {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
      data,
    };
    return this.instance.delete(url, config);
  }

  //
  // API KEY ===========================

  getWithAPIKey(url) {
    const config = {
      headers: {
        Authorization: `Api-Key ${API_KEY_FROM_E_FUTURE}`,
      },
    };
    return this.instance.get(url, config);
  }

  postWithAPIKey(url, data) {
    const config = {
      headers: {
        Authorization: `Api-Key ${API_KEY_FROM_E_FUTURE}`,
      },
    };
    return this.instance.post(url, data, config);
  }

  patchWithAPIKey(url, data) {
    const config = {
      headers: {
        Authorization: `Api-Key ${API_KEY_FROM_E_FUTURE}`,
      },
    };
    return this.instance.patch(url, data, config);
  }

  deleteWithAPIKey(url, data) {
    const config = {
      headers: { Authorization: `Api-Key ${API_KEY_FROM_E_FUTURE}` },
      data,
    };
    return this.instance.delete(url, config);
  }

}

const newAxiosService = new AxiosService();

const logoutWhenHasError = () => {
  removeToken();
  removeStorage();
  store.dispatch(setUserProfileToNullAction());
  store.dispatch(logoutAction());
};

newAxiosService.instance.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    AlertError(i18n.t("Network Error"));
    return {
      status: "Network Error",
      data: null,
    };
  } else {
    const { status } = error.response; //status, data, config
    if (status === 400) {
      const { isAuthenticated } = store.getState().authentication;
      if (isAuthenticated) {
        logoutWhenHasError();
      }
      // AlertError("Bad request!");
      return {
        status: 400,
        data: null,
      };
    }
    if (status === 401) {
      AlertError(i18n.t("Unauthorized!"));
      logoutWhenHasError();
      return {
        status: 401,
        data: null,
      };
    }
    if (status === 406) {
      return {
        status: 406,
        data: null,
      };
    }
    // HTTP_ME_450_EMAIL_EXIST
    if (status === 450) {
      return {
        status: 450,
        data: null,
      };
    }
    // HTTP_ME_451_EMAIL_DOES_NOT_EXIST
    if (status === 451) {
      return {
        status: 451,
        data: null,
      };
    }
    // HTTP_ME_452_ACCOUNT_IS_NOT_ACTIVATED
    if (status === 452) {
      return {
        status: 452,
        data: null,
      };
    }
    // HTTP_ME_453_ACCOUNT_IS_ACTIVATED
    if (status === 453) {
      return {
        status: 453,
        data: null,
      };
    }
    // HTTP_ME_454_OLD_PASSWORD_IS_INCORRECT
    if (status === 454) {
      return {
        status: 454,
        data: null,
      };
    }
    // HTTP_ME_455_TOKEN_INCORRECT_OR_EXPIRED
    if (status === 455) {
      return {
        status: 455,
        data: null,
      };
    }
    // HTTP_ME_456_SLUG_EXIST
    if (status === 456) {
      return {
        status: 456,
        data: null,
      };
    }
    // HTTP_ME_457_CODE_EXIST
    if (status === 457) {
      return {
        status: 457,
        data: null,
      };
    }
    // HTTP_ME_458_DUPLICATE
    if (status === 458) {
      return {
        status: 458,
        data: null,
      };
    }
    if (status === 500) {
      // AlertError("Internal server!");
      return {
        status: 500,
        data: null,
      };
    }
    if (status === 600) {
      return {
        status: 600,
        data: null,
      };
    }
  }
});

export default newAxiosService;
